import { FieldValues, useForm } from "@pankod/refine-react-hook-form";
import { Box, Button, CircularProgress, FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, TextField, TextareaAutosize } from '@pankod/refine-mui'
// import { useLogout } from "@pankod/refine-core";
// import { useNavigate } from "@pankod/refine-react-router-v6";
import { useNotification, useOne } from '@pankod/refine-core';
import { useParams } from 'react-router-dom';
import { ColorModeContext } from "contexts";
import { useContext, useEffect, useState } from "react";

const CreateProduct = () => {
  let { id } = useParams();
  const { open } = useNotification();
  const { mode } = useContext(ColorModeContext);
  const textColor = mode === 'light' ? 'black' : 'white';

  // const navigate = useNavigate();
  // const { mutate: mutateLogout } = useLogout();

  const { data, } = useOne({ resource: "Products", id: id as string,});

  const [sellPrice, setSellPrice] = useState(0);
  const [sellPriceWithVAT, setSellPriceWithVAT] = useState(0);

  const handleSellPriceChange = (e:any) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      setSellPrice(value);
      setSellPriceWithVAT(value * 1.18);
    }
  };

  const handleSellPriceWithVATChange = (e:any) => {
      const value = parseFloat(e.target.value);
      if (!isNaN(value)) {
        setSellPriceWithVAT(value);
        setSellPrice(value / 1.18);
      }
  };


  useEffect(() => {
    if (data?.data) {
      const product = data.data;
      setSellPrice(product.sellPrice);
      setSellPriceWithVAT(product.sellPrice * 1.18);
    }
  }, [data]);

  const {
    refineCore: { onFinish, formLoading, },
    register,
    handleSubmit,
    //setValue,
  } = useForm<any>({
    refineCoreProps: {
      onMutationError: (data:any, variables:any, context:any) => {
          if (data?.response) {
            let responseMessage = data?.response?.data?.message
            open?.({
              type: "error",
              message: responseMessage,
              undoableTimeout: 6,
              description: 'טעות בנתונים',
            });
          }
        },
    },
  });

  const onFinishHandler = async (data: FieldValues) => {
    await onFinish({
        ...data,
        sellPrice: sellPrice
    });
  };

  return (
    <Box
    borderRadius="10px"
    padding="15px">
      <form onSubmit={handleSubmit(onFinishHandler)} style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
       }}>

        <FormHelperText sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', color: textColor}}>
            שינוי שם מוצר
        </FormHelperText>
        <FormControl fullWidth>
        <TextField
          fullWidth
          required
          id="outlined-basic"
          color="info"
          variant="outlined"
          inputProps={{ style: { direction: 'rtl', color: textColor, fontSize: '18px' } }}
          {...register("name", { required: true })}
        />
        </FormControl><br/>

        <FormHelperText sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', color: textColor}}>
            שינוי מספר פריט
        </FormHelperText>
        <FormControl fullWidth>
        <TextField
          fullWidth
          required
          id="outlined-basic"
          color="info"
          variant="outlined"
          inputProps={{ style: { direction: 'rtl', color: textColor, fontSize: '18px' } }}
          {...register("productCode", { required: true })}
        />
        </FormControl><br/>

        <FormHelperText sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', color: textColor}}>
            שינוי ההסבר
        </FormHelperText>
        <FormControl fullWidth>
        <TextareaAutosize
          style={{
            width: "100%",
            background: "transparent",
            fontSize: '18px',
            borderColor: textColor,
            borderRadius: 6,
            padding: 10,
            color: textColor,
            direction: 'rtl', 
            resize: 'none',
          }}
          minRows={1}
          color="primary"
          id="outlined-basic"
          {...register("description", )}
        />
        </FormControl><br/>

        <FormHelperText sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', color: textColor}}>
            שינוי המיקום במחסן
        </FormHelperText>
        <FormControl fullWidth>
        <TextField
          fullWidth
          id="outlined-basic"
          color="info"
          variant="outlined"
          inputProps={{ style: { direction: 'rtl', color: textColor, fontSize: '18px' } }}
          {...register("place", )}
        />
        </FormControl><br/>

        <FormControl fullWidth>
          <FormHelperText sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', color: textColor}}>
          שינוי כמות מוצר 
          </FormHelperText>
          <TextField
           type="number"
           fullWidth
           required
           id="outlined-basic"
           color="info"
           variant="outlined"
           inputProps={{ style: { color: textColor, fontSize: '18px' } }}
          {...register("quantity", { required: true })}/>
        </FormControl><br/><br/>

        <FormControl fullWidth>
        <InputLabel htmlFor="outlined-adornment-amount" sx={{fontSize: '21px', fontWeight: 'bold', color: textColor}}>שינוי מחיר קניה</InputLabel>
          <OutlinedInput 
            type="number"
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">₪</InputAdornment>}
            inputProps={{
              step: 0.0000000000000000000000001,
              pattern: "^[0-9]*\\.?[0-9]+$",
              style: { color: textColor, fontSize: '18px' }
            }}
            label="Amount"
            {...register("getPrice", { required: true })}
          />
        </FormControl><br/><br/>

        <FormControl fullWidth>
        <InputLabel htmlFor="outlined-adornment-amount" sx={{fontSize: '21px', fontWeight: 'bold', color: textColor}}>שינוי מחיר מכירה</InputLabel>
          <OutlinedInput 
            type="number"
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">₪</InputAdornment>}
            inputProps={{
              step: 0.0000000000000000000000001,
              pattern: "^[0-9]*\\.?[0-9]+$",
              style: { color: textColor, fontSize: '18px' }
            }}
            label="Amount"
            value={sellPrice}
            onChange={handleSellPriceChange}
            //{...register("sellPrice", { required: true })}
          />
        </FormControl><br/><br/>

        <FormControl fullWidth>
        <InputLabel htmlFor="outlined-adornment-amount" sx={{fontSize: '21px', fontWeight: 'bold', color: textColor}}>שינוי מחיר מכירה כולל מע"ם</InputLabel>
          <OutlinedInput 
            type="number"
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">₪</InputAdornment>}
            inputProps={{
              step: 0.0000000000000000000000001,
              pattern: "^[0-9]*\\.?[0-9]+$",
              style: { color: textColor, fontSize: '18px' }
            }}
            label="Amount"
            value={sellPriceWithVAT}
            onChange={handleSellPriceWithVATChange}
          />
        </FormControl><br/><br/>

        <input type="hidden" {...register("id")} value={id} />


        <Button type="submit" variant="contained" color="success" disabled={formLoading}>עריכה</Button>
            {formLoading && <CircularProgress/>}
      </form>
    </Box>
  )
}

export default CreateProduct