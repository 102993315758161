import { useTable, useNotification } from '@pankod/refine-core';
import { Autocomplete, Box, Button, Card, CardContent, CircularProgress, FormControl, FormHelperText, Stack,
Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextareaAutosize, TextField, Typography} from '@pankod/refine-mui'
import { FieldValues, useForm } from "@pankod/refine-react-hook-form";
import AddProductDialog from "../components/addProductDialog"
import { useState } from 'react';
import { Add, Delete, } from '@mui/icons-material';
import AddNewProductDialog from 'components/addNewProductDialog';
import React, { useContext, } from "react";
import { ColorModeContext } from "contexts";

type Product = { _id: string; name: string; sellPrice: number, quantity: number, productCode: String, productDescription: String };
type NewProductData = {
  productName: string;
  productCode: string;
  quantity: number;
  firstPrice: number;
  deduction: number;
  lastPrice: number;
};

const CreateOrder = () => {
  const { mode } = useContext(ColorModeContext);
  const textColor = mode === 'light' ? 'black' : 'white';

  const { open } = useNotification();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialog2, setOpenDialog2] = useState(false);
  const [chosenCustomer, setChosenCustomer] = React.useState({});
  const [chosenProducts, setChosenProducts] = useState<{ product: Product; chosenQuantity: number, dedication: Number }[]>([]);
  const [finalPrice, setFinalPrice] = useState(0);
  const [dedication, setDedication] = useState(0);
  const [fakeId, setFakeId] = useState(0)
  const [vat] = useState(18);
  const { tableQueryResult: customersTableQueryResult  }
    = useTable({ resource: "Customers" })
  let customers = customersTableQueryResult.data?.data ?? [];

  const {
    refineCore: { onFinish, formLoading },
    register,
    handleSubmit,
  } = useForm<any>({
    refineCoreProps: {
      onMutationError: (data:any, variables:any, context:any) => {
          if (data?.response) {
            let responseMessage = data?.response?.data?.message
            open?.({
              type: "error",
              message: responseMessage,
              undoableTimeout: 6,
              description: 'טעות בנתונים',
            });
          }
        },
    },
  });

  const onFinishHandler = async (data: FieldValues) => {
    await onFinish({
        ...data,
        dedication: dedication,
        products: chosenProducts,
        customer: chosenCustomer,
        vat: vat,
    });
  };

  const handleClickAddProduct = (product: Product, chosenQuantity: number, dedication: number) => {
    for (const p of chosenProducts) {
      if (p.product._id === product._id) {
        return;
      }
    }
    if (Number(dedication) > 100 || Number(dedication) < 0) { return }
    setChosenProducts([...chosenProducts, { product, chosenQuantity, dedication }]);
    setFinalPrice(finalPrice + (product.sellPrice * (1 - (dedication/100)) * chosenQuantity));
  };

  const handleClickAddProduct2 = (data: NewProductData) => {
    const { productName, productCode, quantity, firstPrice, deduction, lastPrice } = data;
    if (Number(deduction) > 100 || Number(deduction) < 0) { return }
    const newProduct: Product = {
      _id: String(fakeId),
      name: productName,
      sellPrice: firstPrice,
      quantity: quantity,
      productCode: productCode,
      productDescription: '',
    };
    setChosenProducts([...chosenProducts, { product: newProduct, chosenQuantity: quantity, dedication: deduction }]);
    setFinalPrice(finalPrice + (lastPrice * quantity));
    setFakeId(fakeId + 1)
  };

  const handleRemoveProductButton = (p : any) => {
    setChosenProducts(chosenProducts.filter(product => product.product._id !== p.product._id))
    setFinalPrice(finalPrice - (p.product.sellPrice * (1 - (p.dedication/100)) * p.chosenQuantity));
  }

  const handleDedicationChange = (e : any) => {
    let value = Number(e.target.value);
    if (isNaN(value) || value < 0) {
      value = 0;
    } else if (value > 100) {
      value = 100;
    }
    setDedication(value);
  }

  const handleMakeTotalPriceCircle = () => {
    if (isNaN(finalPrice) || isNaN(Number(dedication))) { return }
    const tempPrice = finalPrice * (1 + (vat / 100))
    const floorNum = Number(Math.floor(tempPrice * (1 - (Number(dedication)/100))))
    const res = (1 - (floorNum / tempPrice)) * 100;
    if(!isNaN(res)) { setDedication(res); }
  }

  return (
    <form onSubmit={handleSubmit(onFinishHandler)} style={{ 
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
     }}>

      <FormHelperText sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', color: textColor}}>
        :תיאור
      </FormHelperText>
      <FormControl fullWidth>
        <TextareaAutosize
          style={{
            width: "100%",
            background: "transparent",
            fontSize: "17px",
            borderColor: textColor,
            borderRadius: 6,
            padding: 10,
            color: textColor,
            direction: 'rtl', 
            resize: 'none',
          }}
          minRows={4}
          color="primary"
          id="outlined-basic"
          {...register("description")}
        />
      </FormControl><br/>

      <FormHelperText sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', color: textColor}}>
        :לקוח
      </FormHelperText>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        disableClearable
        options={customers}
        getOptionLabel={(option) => {
          const customerLabel = option?.name ?? "";
          const phoneNumber = option?.phoneNumber ?? "";
          const customerNumber = option?.customerNumber ?? "";
          const customerDetails = `${customerLabel} - ${phoneNumber}`;
          return customerNumber ? `${customerDetails} - ${customerNumber}` : customerDetails;
        }}
        onChange={((event, customerGetChosen) => {
          setChosenCustomer(customerGetChosen)
        })}
        renderInput={(params) => 
        <TextField {...params} label="" sx={{ direction: 'rtl' }} />}
      /><br/>
        <Stack spacing={2} direction='row-reverse' justifyContent="space-between">
          <Typography sx={{fontWeight: "bold", fontSize: 22, color: textColor}}>:מוצרים</Typography>
          <Stack direction={{ xs: 'column', sm: 'row-reverse' }}
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}>
            <Button onClick={() => setOpenDialog(true)}
              sx={{borderRadius: 10, maxWidth: 200 }} variant="contained">הוספת מוצר<Add/></Button>
            <Button onClick={() => setOpenDialog2(true)} variant="contained"
               sx={{borderRadius: '30px', backgroundColor: '#47C7F9', boxShadow: 2, color: 'white'}}>הוספת מוצר לא במחסן<Add/></Button>
          </Stack>
        </Stack>
      <br/>
      <Box sx={{ display: { xs: 'none', sm: 'block' }}}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              
              <TableCell sx={{fontWeight: "bold", fontSize: 19, color: textColor, textAlign: 'center'}}>הסרה</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, color: textColor, textAlign: 'right'}}>כולל מע"ם</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, color: textColor, textAlign: 'right'}}>מ. לפי כמות</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, color: textColor, textAlign: 'right'}}>כמות</TableCell>

              <TableCell sx={{fontWeight: "bold", fontSize: 19, color: textColor, textAlign: 'right'}}>מ. אחרי הנחה</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, color: textColor, textAlign: 'right'}}>הנחה</TableCell>

              <TableCell sx={{fontWeight: "bold", fontSize: 19, color: textColor, textAlign: 'right'}}>מחיר</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, color: textColor, textAlign: 'right'}}>מספר פריט</TableCell>
              <TableCell sx={{fontWeight: "bold", fontSize: 19, color: textColor, textAlign: 'right'}}>שם מוצר</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {chosenProducts.map((p) => (
              <TableRow>
                <TableCell sx={{fontSize: 19}}><Button onClick={() => handleRemoveProductButton(p)}
                sx={{borderRadius: '30px', backgroundColor: '#FF0000', boxShadow: 2, color: 'white'}}><Delete/></Button></TableCell>
                <TableCell sx={{fontSize: 19, color: textColor, textAlign: 'right'}}>{Number(p.chosenQuantity * p.product.sellPrice * 1.18 * (1 - (Number(p.dedication)/100))).toFixed(2)} ₪</TableCell>
                <TableCell sx={{fontSize: 19, color: textColor, textAlign: 'right'}}>{Number(p.chosenQuantity * p.product.sellPrice * (1 - (Number(p.dedication)/100))).toFixed(2)} ₪</TableCell>
                <TableCell sx={{fontSize: 19, color: textColor, textAlign: 'right'}}>{p.chosenQuantity}/{p.product.quantity}</TableCell>

                <TableCell sx={{fontSize: 19, color: textColor, textAlign: 'right'}}>{Number(p.product.sellPrice * (1 - (Number(p.dedication)/100))).toFixed(2)} ₪</TableCell>
                <TableCell sx={{fontSize: 19, color: textColor, textAlign: 'right'}}>{Number(p.dedication)} %</TableCell>

                <TableCell sx={{fontSize: 19, color: textColor, textAlign: 'right'}}>{Number(p.product.sellPrice).toFixed(2)} ₪</TableCell>
                <TableCell sx={{fontSize: 19, color: textColor, textAlign: 'right'}} style={{ direction: 'rtl', wordBreak: 'break-word', }}>{p.product.productCode}</TableCell>
                <TableCell sx={{fontSize: 19, color: textColor, textAlign: 'right'}} style={{ direction: 'rtl', wordBreak: 'break-word', }}>{p.product.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer><br/>
      </Box>
      <Box sx={{ display: { xs: 'block', sm: 'none' }}}>
        <Stack direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={1.5}>
          {chosenProducts.map((p:any) => (
            <Card sx={{ boxShadow: 2, borderRadius: 3, border: 1 }} key={p.product._id}>
              <CardContent>
                <Stack direction='column' justifyContent="space-between" spacing={0.3}> 
                  <Typography fontSize={22} fontWeight='bold' sx={{textAlign: 'right', color: textColor}} style={{ direction: 'rtl', }}>
                    {p.product.name}
                  </Typography>
                  <Typography sx={{fontSize: '19px', textAlign: 'right', color: textColor}} style={{ direction: 'rtl', }}>מק"ט: {p.product.productCode}</Typography>
                  <Stack direction='row-reverse' justifyContent="space-between">
                    <Typography sx={{fontSize: '19px', textAlign: 'right', color: textColor}}>מחיר ליחידה: ₪{p.product.sellPrice}</Typography>
                    <Typography sx={{fontSize: '19px', textAlign: 'right', color: textColor}}>הנחה: {Number(p.dedication).toFixed(2)}%</Typography>
                  </Stack>
                  <Typography sx={{fontSize: '19px', textAlign: 'right', color: textColor}}>מ.אחרי הנחה: {Number(p.product.sellPrice * (1-(Number(p.dedication)/100))).toFixed(2)}₪</Typography>
                  <Typography sx={{fontSize: '19px', textAlign: 'right', color: textColor}}>כמות: {p.chosenQuantity}</Typography>
                  <Typography sx={{fontSize: '19px', fontWeight: 'bold', textAlign: 'left', color: textColor}}>סכ"ה: ₪{Number(p.chosenQuantity * p.product.sellPrice * (1-(Number(p.dedication)/100))).toFixed(2)}</Typography>
                </Stack>
                <Button onClick={() => handleRemoveProductButton(p)}
                  sx={{borderRadius: '30px', backgroundColor: '#FF0000', boxShadow: 2, color: 'white'}}><Delete/></Button>  
              </CardContent>
            </Card>
          ))}
        </Stack>
        <br/>
      </Box><br/>
      {openDialog && <AddProductDialog  dialogState={setOpenDialog} yesFunction={handleClickAddProduct}/>}
      {openDialog2 && <AddNewProductDialog dialogState={setOpenDialog2} yesFunction={handleClickAddProduct2}/>}
      <Typography sx={{fontSize: '18px', fontWeight: 'bold', color: textColor}}>סכ"ה: {Number(finalPrice).toFixed(2)}</Typography><br/>
      <TextField
        value={dedication}
        inputProps={{
          min: 0, max: 100,
          step: 0.0000000000000000000000001,
          pattern: "^(?:\\d+)?(?:\\.\\d+)?$",
        }}
        label="הנחה באחוז"
        variant="outlined"
        type="number"
        id="dedication"
        onChange={handleDedicationChange}
      /> <br/>
      <Typography sx={{fontSize: '19px', fontWeight: 'bold', color: textColor}}>סכ"ה אחרי הנחה: {Number(finalPrice * (1 - (Number(dedication)/100))).toFixed(2)}</Typography><br/>

      <Box sx={{ display: 'flex', alignItems: 'center', }}>
        <Typography sx={{ fontSize: '19px', fontWeight: 'bold', color: textColor }}>
          {Number(finalPrice * (1 + (vat / 100)) * (1 - (dedication / 100))).toFixed(2)} :(18%) 
        </Typography>
        <Typography sx={{ fontSize: '19px', fontWeight: 'bold', color: textColor }}>
          מע"ם
        </Typography>
      </Box>



      <Stack direction={{ xs: 'column', sm: 'row-reverse' }} 
             justifyContent="flex-end"
             alignItems="flex-start"
             spacing={2}>
        <Button onClick={() => handleMakeTotalPriceCircle()}
              sx={{ borderRadius: '30px', backgroundColor: '#0051FF', boxShadow: 2, color: 'white' }} >עיגול הסכום</Button>
        <Typography sx={{fontSize: '19px', fontWeight: 'bold', color: textColor}}>סכ"ה לתשלום: {Number(finalPrice * (1 - (Number(dedication)/100)) * (1 + (vat / 100))).toFixed(2)}</Typography><br/>
      </Stack> <br/>
      <TextField
        defaultValue={0}
        inputProps={{ 
          min: 0, 
          step: 0.0000000000000000000000001,
          pattern: "^\\d+(\\.\\d{0,2})?$",
        }}
        label=":שולם"
        variant="outlined"
        type="number"
        id="Payed"
        {...register("Payed")}
      /> <br/>
      <Button type="submit" variant="contained" color="success" disabled={formLoading}>הוספה</Button>
          {formLoading && <CircularProgress/>}
     </form>
  )
}

export default CreateOrder