import { Delete, Edit, Payments, Visibility } from '@mui/icons-material';
import { useLogout, useShow, useTable, useNotification, } from '@pankod/refine-core'
import { useNavigate } from '@pankod/refine-react-router-v6'
import { Box, Button, Card, CircularProgress, Stack, Typography, CardContent, CardActions, TableCell, TableBody, TableHead, TableRow, Table, TableContainer, Tabs, Tab, Chip } from '@pankod/refine-mui';
import MenuDialog from 'components/menuDialog';
import { useState, useContext } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import axios from 'axios';
import ConfirmationDialog from 'components/ConfirmationDialog';
import AddPaymentModal from 'components/AddPaymentModal';
import { ColorModeContext } from "contexts";
import { url } from 'assets/url';

const SupplierDetails = () => {
  const navigate = useNavigate();
  const { queryResult } = useShow();
  const { open, } = useNotification();
  const { mutate: mutateLogout } = useLogout();
  const [dialogStatus, setDialogStatus] = useState(false);
  const [filteredSupplies, setFilteredSupplies] = useState([]);
  const [filteredSuppliesPayed, setFilteredSuppliesPayed] = useState([]);
  const [totalFilteredSuppliesPrice, setTotalFilteredSuppliesPrice] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const [paymentDialogStatus, setPaymentDialogStatus] = useState(false);
  const { data, isLoading, isError, isRefetching, refetch  } = queryResult;
  const supplier = data?.data ?? {};  
  const allPayments = data?.data.allPayments ?? [];
  const { mode } = useContext(ColorModeContext);
  console.log(filteredSuppliesPayed)
  const { tableQueryResult } = useTable({ resource: `Suppliers/SupplierSupply/${supplier?._id}`})
  const supplies = tableQueryResult.data?.data ?? [];
  const [value, setValue] = useState(0);
  const handleChange = (event:any, newValue:any) => {
    setValue(newValue);
  }

  const [addPaymentStatus ,setAddPaymentStatus] = useState(false);
  const [deletedPayment ,setDeletedPayment] = useState(false);

  const backgroundColor = mode === "light" ? "#343434" : "#FFFFFF";
  const tableHead = mode === "light" ? "#D9D9D9" : " #333333";

  if(isLoading || isRefetching) return <center><CircularProgress disableShrink /></center>
  
  if(isError) { 
    mutateLogout() 
    navigate('/login') 
  }

  const getSupplierMenu = (dateData: any) => {
    axios.post(`${url}/Suppliers/Menu`,
    {data: dateData, supplierId: supplier?._id}, { withCredentials: true, }
    ).then((res) => {
      const resData = res.data;
      if (resData.totalPrice && resData.filteredSupplies) {
        setFilteredSupplies(resData.filteredSupplies);
        setFilteredSuppliesPayed(resData.payed);
        setTotalFilteredSuppliesPrice(resData.totalPrice);
        setShowMenu(true);
        setDialogStatus(false)
      }
    }).catch((error:any) => {
      let errMsg = error.response?.data.message || 'שגיאה'
      open?.({
        type: "error",
        message: errMsg,
        undoableTimeout: 6,
        description: 'שגיאה',
      });
    });
  }

  const handleClickMenuGetPaid = () => {
    if (filteredSupplies.length !== 0) {
      axios.post(`${url}/Suppliers/MenuPayed`, 
      {supplierId: supplier?._id, filteredSupplies},
      {withCredentials: true, responseType: 'json'}).then((res) => {
        setPaymentDialogStatus(false);
        setShowMenu(false);
        open?.({
          type: 'success',
          message: 'התעדכן שהתפריט שולם',
          undoableTimeout: 6,
          description: "התעדכן בהצלחה",
        });
        navigate("/Suppliers")
      }).catch((error: any) => {
        console.error('Error:', error);
        open?.({
          type: "error",
          message: error.response?.data?.message || "שגיאה במחיקת המוצר",
          undoableTimeout: 6,
          description: "שגיאה",
        });
      });
    }
  }

  const handleDeletePayment = (item:any) => {
    axios.delete(`${url}/Suppliers/${supplier?._id}/Payments/delete/${item}`,
    {withCredentials: true, responseType: 'json'}).then((res) => {
      setDeletedPayment(false);
      refetch();
    })
  }

  return (
    <Box>
      <Stack direction="row-reverse"
            justifyContent="space-between"
            alignItems="flex-start">
        <Typography fontSize='28px' fontWeight='bold' style={{direction: 'rtl', }}>
          {supplier?.companyName}
        </Typography>
        <Stack direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={2}>
          <Button onClick={() => navigate(`/Suppliers/edit/${supplier?._id}`)} 
            sx={{boxShadow: 3, borderRadius: 10}} variant="contained" startIcon={<Edit/>}>עריכה</Button>

          <Button onClick={() => {setDialogStatus(true)}} sx={{ display: { xs: 'none', sm: 'none', md: 'block' },
              boxShadow: 3, borderRadius: 10, bgcolor: '#60D5FC' }} variant="contained" startIcon={<MenuIcon/>}>תפריט</Button>
          <Button onClick={() => setAddPaymentStatus(true)} sx={{boxShadow: 3, borderRadius: 10, bgcolor: '#577BFC', color: '#FFFFFF'}}
              variant="contained" startIcon={<Payments/>}>הוספת תשלום</Button>
        </Stack>
      
      </Stack><br/>
      <Stack
        direction={{xs: 'column', sm: 'row-reverse'}}
        justifyContent="space-between"
        alignItems='flex-end'>
        <Typography sx={{fontSize: '23px', fontWeight: 'bold', direction: 'rtl', }}>מס" חברה: {supplier?.companyNumber}</Typography>
        <Typography sx={{fontSize: '23px', fontWeight: 'bold'}}>{supplier?.phoneNumber} :מספר טל</Typography>
        {(supplier?.dept > 0 && <Typography sx={{bgcolor:'#FA8A79', fontSize: '23px', fontWeight: 'bold'}}>{Number(supplier?.dept).toFixed(2)} :חוב</Typography>) || 
        (supplier?.dept === 0 && <Typography sx={{bgcolor:'#A6FD92', fontSize: '23px', fontWeight: 'bold'}}>{supplier?.dept} :חוב</Typography>) }
        <Typography sx={{fontSize: '23px', fontWeight: 'bold'}}>{supplies.length} :הזמנות</Typography>
        {(supplier?.credit > 0 && <Typography sx={{bgcolor:'#FBAC7A', fontSize: '23px', fontWeight: 'bold', borderRadius:'10px'}}>{Number(supplier?.credit).toFixed(2)} :זיכוי</Typography>) || 
        (supplier?.credit === 0 && <Typography sx={{bgcolor:'#ADF79C', fontSize: '23px', fontWeight: 'bold', borderRadius:'10px'}}>{supplier?.credit} :זיכוי</Typography>) }
      </Stack><br/>

      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="basic tabs example"
        variant="fullWidth"
        allowScrollButtonsMobile
        scrollButtons
        centered
        sx={{ direction: 'rtl' }}
      >
        <Tab label="קניות" sx={{ fontSize: '20px', fontWeight: 'bold', color: backgroundColor }} />
        <Tab label="תשלומים" sx={{ fontSize: '20px', fontWeight: 'bold', color: backgroundColor }} />
      </Tabs>

      <TabPanel value={value} index={0}
      >
        {/* ========== Supplies PC display ========== */}
        <Box sx={{ display: { xs: 'none', md: 'block' }}}>
          {!showMenu && ( 
          <TableContainer sx={{ direction: 'rtl', }}>
            <Table sx={{ minWidth: 700 }}>
              <TableHead>
                <TableRow sx={{ bgcolor: tableHead }}>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21 }}>מספר הזמנה</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21 }}>זמן ההזמנה</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21 }}>סטטוס</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21 }}>זיכוי</TableCell>
                  <TableCell sx={{ textAlign: 'center', fontWeight: 'bold', fontSize: 21 }}>פעולות</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {supplies.length === 0 && <h3>אין הזמנות</h3>}
                {supplies.slice().reverse().map((supply) => (
                    <TableRow>
                      <TableCell sx={{ textAlign: 'center', fontSize: 20 }}>{supply.invoicingNum.toString().padStart(6, '0')}</TableCell>
                      <TableCell sx={{ textAlign: 'center', fontSize: 20 }}>{new Date(supply.Time).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' })}</TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        {supply.ifPayed ? (
                          <Chip label="שולם" sx={{ fontSize: '19px', bgcolor: '#99FA60',  }} />
                        ) : (
                          <Chip label="לא שולם" sx={{ fontSize: '19px', bgcolor: '#FA8A79', borderRadius: '10px' }} />
                        )}
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                        <Chip
                          label={supply.credit > 0 ? supply.credit : "0"}
                          sx={{
                            fontSize: 20,
                            bgcolor: supply.credit > 0 ? (mode === 'light' ? '#FFDAB9' : '#FF8C00') : 'inherit',
                            borderRadius: '10px'
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ textAlign: 'center' }}>
                          <Button color="primary" onClick={() => navigate(`/Supplies/show/${supply._id}`)}>
                            <Visibility />
                          </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>)}
        </Box>

        {/* ========== Supplies Phones Display ========== */}
        {!showMenu && 
        <Stack sx={{ mt: 1, }} spacing={1.2}>
          {supplies.map((supply : any) => (
            <Card sx={{ boxShadow: 5, borderRadius: '17px', display: { xs: 'block', sm: 'block', md: 'none' }, border: 1, }} key={supply._id}>
              <CardContent>
                <Typography fontSize='21px' fontWeight='bold' textAlign={'right'}>
                  {supply.invoicingNum}
                </Typography>
                <Stack direction='row-reverse' justifyContent="space-between">
                  <Stack direction="column" justifyContent="flex-start">
                    <Typography fontSize='20px' fontWeight='bold' textAlign={'right'}>{new Date(supply.Time).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' })}</Typography>
                    {(supply.credit > 0 && <Typography align='center' fontWeight='bold' sx={{fontSize:'20px', bgcolor:'#FBAC7A', borderRadius:'10px'}}>{supply.credit} :זיכוי</Typography>)}
                    <Typography fontSize='20px' fontWeight='bold' textAlign={'right'}>מוצרים: {supply.supplyProducts.length}</Typography>
                    {(!supply.ifPayed && <Typography align='center' fontWeight='bold' sx={{fontSize:'20px', bgcolor:'#FA8A79', borderRadius:'10px'}}>לא שולם</Typography>)
                    || (supply.ifPayed && <Typography align='center' fontWeight='bold' sx={{fontSize:'20px', bgcolor:'#99FA60', borderRadius:'10px'}}>שולם</Typography>)}
                  </Stack>
                  <CardActions>
                    <Button variant="text" sx={{ fontSize:18 ,background: '#475BE8', borderRadius: '30px', color: '#ffffff', boxShadow: 2}}
                    onClick={() => navigate(`/Supplies/show/${supply._id}`)}>פרטים</Button>
                  </CardActions>
                </Stack>
              </CardContent>
            </Card>
          ))}
        </Stack>}

        {/* ========== Cartesian ========== */}
        {showMenu && 
        <Box>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow sx={{bgcolor: '#232323'}}>          
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>סב"כ בש"ח</TableCell>   
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>מחיר נטו</TableCell>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>הנחה</TableCell>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>מחיר ליחיד</TableCell>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>כמות</TableCell>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>מס' פריט</TableCell>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>שם מוצר</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredSupplies.map((supply : any) => (
                  <>
                  {supply.supplyProducts.map((product: any) => (
                    <TableRow key={product._id}>               
                      <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>
                        {Math.round(product.product.quantity * product.product.firstPrice * (1 - (product.product.deduction / 100)) * 100) / 100} ₪
                      </TableCell>
                      <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>
                        {Math.round((1 - (product.product.deduction / 100)) * product.product.firstPrice * 100) / 100} ₪
                      </TableCell>
                      <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>
                        {Math.round(product.product.deduction * 100) / 100}%
                      </TableCell>
                      <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>
                        {Math.round(product.product.firstPrice * 100) / 100} ₪
                      </TableCell>
                      <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>
                        {product.product.quantity}
                      </TableCell>
                      <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', wordBreak: 'break-word'}} style={{ direction: 'rtl' }}>
                        {product.product.productCode}
                      </TableCell>
                      <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', wordBreak: 'break-word'}} style={{ direction: 'rtl' }}>
                        {product.product.productName}
                      </TableCell>
                    </TableRow>
                  ))}
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer><br/>
          <Box style={{ textAlign: 'right' }}>
            <Typography fontSize='25px' style={{ direction: 'rtl', }}>סכ"ה כולל הנחות: {totalFilteredSuppliesPrice}</Typography>
            <Typography fontSize='25px' style={{ direction: 'rtl', }}>כולל מע"מ: {Number(totalFilteredSuppliesPrice) * 1.18}</Typography>
          </Box>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={4}
          >
            <Button onClick={() => setPaymentDialogStatus(true)} sx={{boxShadow: 3, borderRadius: 10,
                bgcolor: '#FC2E2E'}} variant="contained">לסמן שהכרטיסת ששולמה בשלמותה</Button>
            <Button onClick={() => setShowMenu(false)} sx={{ fontSize: '16px', bgcolor: 'gray', color: 'white', borderRadius: 3 }}>סגור</Button>
          </Stack>
        </Box>}
      </TabPanel>

      {/* ========== Payments PC display ========== */}
      <TabPanel value={value} index={1}>
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, direction: 'rtl'}}>
          <Table>
            <TableHead sx={{ bgcolor: tableHead }}>
              <TableRow>
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>סוג תשלום</TableCell>
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>בנק</TableCell>
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>חשבון</TableCell>
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>תאריך</TableCell>
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>סכום</TableCell>
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>איש</TableCell>
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>פעולות</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              { allPayments.length === 0 ? <h3>אין תשלומים</h3> : (allPayments.map((payment: any) => (
               <TableRow key={payment.id}>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>
                    {payment.paymentType === 'check' ? `ציק - ${payment.payment?.number}` : 
                    payment.paymentType === 'bankTransfer' ? 'העברה בנקאית' : 
                    payment.paymentType === 'cash' ? 'מזומן' : 'ויזה (Visa)'}
                  </TableCell>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{payment.payment?.bank} - {payment.payment?.branch}</TableCell>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{payment.payment?.account}</TableCell>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{payment.payment?.date?.day}/{payment.payment?.date?.month}/{payment.payment?.date?.year}</TableCell>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{payment.payment?.price}₪</TableCell>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{payment.payment?.person}</TableCell>
                  <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>
                    <Button sx={{borderRadius: '30px', backgroundColor: '#FF0000', boxShadow: 2, color: 'white'}} onClick={()=>setDeletedPayment(payment._id)}><Delete /></Button>
                  </TableCell>
                </TableRow>
              )))}
            </TableBody>
          </Table>
        </Box>

        {/* ========== Payments Phone display ========== */}
        <Box sx={{ mt: 1, display: { xs: 'block', sm: 'block', md: 'none' }}}>
          {allPayments.map((payment:any) => (
            <Stack direction='column-reverse'>
            <Card key={payment.id} sx={{ boxShadow: 5, borderRadius: '17px', marginBottom: 2, border: 1, }}>
              <CardContent>
                <Typography sx={{ fontSize: 20, textAlign: 'right', fontWeight: 'bold', direction: 'rtl' }}>
                  {payment.paymentType === 'check' ? `ציק - ${payment.payment?.number}` : 
                  payment.paymentType === 'bankTransfer' ? 'העברה בנקאית' : 
                  payment.paymentType === 'cash' ? 'מזומן' : 'Unknown Payment Type'}
                </Typography>

                <Stack direction="row-reverse" justifyContent="space-between">
                  <Stack spacing={0.5} direction="column" justifyContent="flex-start">
                    {payment.payment?.bank && (
                      <Typography sx={{ fontSize: 18, textAlign: 'right', direction: 'rtl' }}>
                        בנק: {payment.payment.bank} - {payment.payment.branch}
                      </Typography>
                    )}
                    {payment.payment?.account && (
                      <Typography sx={{ fontSize: 18, textAlign: 'right', direction: 'rtl' }}>
                        חשבון: {payment.payment.account}
                      </Typography>
                    )}
                    <Typography sx={{ fontSize: 18, textAlign: 'right', direction: 'rtl' }}>
                      תאריך: {payment.payment?.date?.day}/{payment.payment?.date?.month}/{payment.payment?.date?.year}
                    </Typography>
                    {payment.payment?.person && (
                      <Typography sx={{ fontSize: 18, textAlign: 'right', direction: 'rtl' }}>
                        איש: {payment.payment.person}
                      </Typography>
                    )}
                    <Typography sx={{ fontSize: 19, textAlign: 'right', direction: 'rtl' }}>
                      סכום: {payment.payment?.price}₪
                    </Typography>
                  </Stack>
                  <CardActions>
                    <Button sx={{borderRadius: '30px', backgroundColor: '#FF0000', boxShadow: 2, color: 'white'}} onClick={() => setDeletedPayment(payment._id)}><Delete /></Button>
                  </CardActions>
                </Stack>
              </CardContent>
            </Card>
            {deletedPayment === payment._id && <ConfirmationDialog dialogState={setDeletedPayment} yesFunction={() => handleDeletePayment(payment.id)} message={`האם אתה רוצה למחוק תשלום מהחשבון ${supplier?.companyName} ששולם בתאריך: ${payment.payment?.date?.day}/${payment.payment?.date?.month}/${payment.payment?.date?.year}`}/>}</Stack>
          ))}
        </Box>
      </TabPanel>

      {dialogStatus && <MenuDialog dialogState={setDialogStatus} yesFunction={getSupplierMenu}/>}
      {paymentDialogStatus && <ConfirmationDialog yesFunction={handleClickMenuGetPaid} dialogState={setPaymentDialogStatus} message={"האם אתה בטוח שרוצה לסמן ההזמנות ששולמו בשלמותם ?"}/>}
      {addPaymentStatus && <AddPaymentModal title={`הוספת תשלום ל- ${supplier?.companyName}`} dialogState={setAddPaymentStatus} yesFunction={()=> refetch()} url={`${url}/Suppliers/${supplier?._id}/Payments/create`} />}
    </Box>
  )
}

export default SupplierDetails

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, } = props;

  if (value === index) {
    // Display the content of this tab panel when it's active
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
      >
        {children}
      </div>
    );
  } else {
    // Hide the content when it's not active
    return null;
  }
}