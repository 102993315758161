import { Add, Delete, Edit, Search, Inventory2Outlined, Receipt } from '@mui/icons-material'
import { Box, Stack } from '@mui/system'
import { useLogout, useDelete, } from '@pankod/refine-core'
import { Button, Card, CardActions, CardContent, CircularProgress, InputAdornment, TextField, Tooltip, Typography } from '@pankod/refine-mui'
import { useNavigate } from '@pankod/refine-react-router-v6'
import { useState, useContext, useEffect, ChangeEvent, useCallback } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '../components/dialog';
import axios from 'axios'
import { url } from 'assets/url'
import { ColorModeContext } from "contexts";

const Products = () => {
  const { mode } = useContext(ColorModeContext);
  const tableHead = mode === "light" ? "#DFDFDF" : " #333333";
  const textColor = mode === "light" ? "black" : "white";

  const navigate = useNavigate();
  const [currentId, setCurrentId] = useState(false);
  const { mutate } = useDelete();
  const [search, setSearch] = useState('');
  const { mutate: mutateLogout } = useLogout();
  const [pdfLoading, setPdfLoading] = useState(false);

  const handleDeleteProduct = (id: String) => {
    mutate(
      {
        resource: "Products",
        id: id as string,
      },
      {
        onSuccess: () => {
          fetchProducts(search, 1);
          setCurrentId(false);
          navigate("/Products");
        },
      },
    );
  };

  /*
  const {
    tableQueryResult: {data, isLoading, isError},
  } = useTable();
    const allProducts = data?.data ?? [];
  if(isLoading) return <center><CircularProgress disableShrink /></center>
  if(isError) { 
    mutateLogout() 
    navigate('/login') 
  }
  */

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [products, setProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [scrollLoading, setScrollLoading] = useState(false);


  const fetchProducts = async (searchTerm = '', pageNum = 1) => {
    setLoading(true);
    try {
        const response = await axios.get(`${url}/products/getProductsWithPagination`, {
          params: { search: searchTerm, page: pageNum, limit: 20 },
          withCredentials: true,
        });
        const { products: fetchedProducts, totalProducts } = response.data;
        setProducts((prevProducts) => pageNum === 1 ? fetchedProducts : [...prevProducts, ...fetchedProducts]);
        setHasMore(fetchedProducts.length > 0 && products.length < totalProducts);
      } catch (error:any) {
        console.error(error);
        if (error?.response && error?.response.status === 401) {
          mutateLogout() 
          navigate('/login') 
        }
      } finally {
        setLoading(false);
      }
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSearch(searchTerm);
    setPage(1);
    fetchProducts(searchTerm, 1);
  };

  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop >= document.documentElement.offsetHeight - 100 && !loading && hasMore) {
      console.log('Fetching more products...');
      setPage((prevPage) => prevPage + 1);
      setScrollLoading(true);
    }
}, [loading, hasMore]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll, loading, hasMore]);
  useEffect(() => {
    fetchProducts(search, page);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ page, search]);
  useEffect(() => {
    setScrollLoading(false);
  }, [products]);

  return (
    <Box style={{ overflowX: "hidden" }}>
      <Box>
        <Stack direction="row-reverse"
              justifyContent="space-between"
              alignItems="flex-start"
              spacing={12}>
          <Typography fontSize='25px'
                  color={textColor}  fontWeight='bold'>
              מוצרים
          </Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 3 }}>
            <Button sx={{boxShadow: 3, borderRadius: 10}} variant="contained" endIcon={<Add/>}
            onClick={() => navigate('/Products/create')}>הוספת מוצר</Button>
            <Button endIcon={!pdfLoading && <Inventory2Outlined/>} sx={{boxShadow: 3, borderRadius: 10, bgcolor: '#578CFE'}} 
              variant="contained" onClick={() => {
                setPdfLoading(true);
                axios.get(`${url}/bids/createPDF`, {withCredentials: true,responseType: 'blob',}).then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'ספירת_מלאי.pdf');
                    document.body.appendChild(link);
                    link.click();
                  }).catch((error) => {
                    console.error(error);
                  }).finally(() => {
                    setPdfLoading(false);
                  });
              }
            }>{pdfLoading ? <CircularProgress size={24} color="inherit" /> : 'ספירת מלאי'}</Button>
            <Button sx={{boxShadow: 3, borderRadius: 10, bgcolor: '#984A4A', display: { xs: 'none', sm: 'block', md: 'block' } }} variant="contained" 
            endIcon={<Receipt/>} onClick={() => navigate('/Bids')}>הצעת מחיר</Button>
          </Stack>
        </Stack>
      </Box><br/>
      <TextField
        fullWidth
        id="input-with-icon-textfield"
        label="חיפוש"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        variant="outlined"
        onChange={handleSearchChange}
        value={search}
        inputProps={{ style: { direction: 'rtl', } }}
        />
      <br/><br/>
      <TableContainer sx={{ display: { xs: 'none', md: 'block' }}}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <TableCell align='center' sx={{fontSize: 21, fontWeight: 'bold', bgcolor: tableHead, color: textColor}}>ערוך\הסר</TableCell>
            <TableCell align='center' sx={{fontSize: 21, fontWeight: 'bold', bgcolor: tableHead, color: textColor}}>רווח</TableCell>
            <TableCell align='center' sx={{fontSize: 21, fontWeight: 'bold', bgcolor: tableHead, color: textColor}}>כולל מע"ם</TableCell>
            <TableCell align='center' sx={{fontSize: 21, fontWeight: 'bold', bgcolor: tableHead, color: textColor}}>מ. מכירה</TableCell>
            <TableCell align='center' sx={{fontSize: 21, fontWeight: 'bold', bgcolor: tableHead, color: textColor}}>מ. קניה</TableCell>
            <TableCell align='center' sx={{fontSize: 21, fontWeight: 'bold', bgcolor: tableHead, color: textColor}}>כמות</TableCell>
            <TableCell align='center' sx={{fontSize: 21, fontWeight: 'bold', bgcolor: tableHead, color: textColor}}>מיקום אחסון</TableCell>
            <TableCell align='center' sx={{fontSize: 21, fontWeight: 'bold', bgcolor: tableHead, color: textColor}}>מק"ט</TableCell>
            <TableCell align='center' sx={{fontSize: 21, fontWeight: 'bold', bgcolor: tableHead, color: textColor}}>שם מוצר</TableCell>
          </TableRow>
        </TableHead>
        {loading && page === 1 ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflowX: 'hidden', overflowY: 'hidden' }}>
            <CircularProgress
              disableShrink
              size={60}
              thickness={5}
              style={{ marginTop: '20px' }}
            />
          </Box>
        ) : (
        <TableBody>
          {products.map((p) => (
            <TableRow key={p._id}>
              <TableCell align='center'>
                <Button onClick={() => navigate(`/Products/edit/${p._id}`)}
                        sx={{borderRadius: '30px', backgroundColor: '#2549FE', boxShadow: 2, color: 'white'}}><Edit/></Button>
                <Button onClick={() => setCurrentId(p._id)}
                        sx={{borderRadius: '30px', backgroundColor: '#FF0000', boxShadow: 2, color: 'white'}}><Delete/></Button>
              </TableCell>
              <TableCell align='center' sx={{fontSize: 20, color: textColor, wordBreak: 'break-word', }}>{Number(p.sellPrice - p.getPrice).toFixed(2)}₪</TableCell>
              <TableCell align='center' sx={{fontSize: 20, color: textColor, wordBreak: 'break-word', }}>{Number(p.sellPrice * 1.18).toFixed(2)}₪</TableCell>
              <TableCell align='center' sx={{fontSize: 20, color: textColor, wordBreak: 'break-word', }}>{p.sellPrice}₪</TableCell>
              <TableCell align='center' sx={{fontSize: 20, color: textColor, wordBreak: 'break-word', }}>{p.getPrice}₪</TableCell>
              <TableCell align='center' sx={{fontSize: 20, color: textColor, wordBreak: 'break-word', }}>{p.quantity}</TableCell>
              <TableCell align='center' sx={{fontSize: 20, color: textColor, wordBreak: 'break-word', }}>{p.place || 'אין'}</TableCell>
              <TableCell align='center' sx={{fontSize: 20, color: textColor, wordBreak: 'break-word', }}>{p.productCode}</TableCell>
              
              <TableCell align='center' sx={{ fontSize: 20, color: textColor, wordBreak: 'break-word' }} style={{ direction: 'rtl' }}> 
                {p.description ? (
                   <Tooltip 
                     title={
                       <Typography style={{ fontSize: '17px', direction: 'rtl', textAlign: 'right' }}>
                         {p.description}
                       </Typography>
                     }
                     placement="left"
                   >
                     <span>{p.name}</span>
                   </Tooltip> ) : (
                   p.name
                 )}
              </TableCell>

            </TableRow>
          ))}
        </TableBody>)}
      </Table>
      {scrollLoading && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflowX: 'hidden', overflowY: 'hidden' }}>
          <CircularProgress
            disableShrink
            size={60}
            thickness={5}
            style={{ marginTop: '20px' }}
          />
        </Box>
      )}
    </TableContainer>
    
    <Box sx={{display: { md: 'none', xs: 'block' }}}>
      <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={1.5}>
            {loading && page === 1 ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflowX: 'hidden', overflowY: 'hidden' }}>
                <CircularProgress
                  disableShrink
                  size={60}
                  thickness={5}
                  style={{ marginTop: '20px' }}
                />
              </Box>
            ) : ( products.map((p) => (
              <>
              <Card sx={{ boxShadow: 5, borderRadius: 5, border: 1 }} key={p._id}>
                <CardContent>
                  <Typography fontSize={24} fontWeight='bold' align='right' style={{ direction: 'rtl', }}>
                    {p.name}
                  </Typography>
                  <Stack direction="row-reverse" justifyContent="space-between"> 
                    <Typography variant="body2">
                      <Stack spacing={0.5} direction="column" justifyContent="flex-start">
                        <Typography align='right' style={{ direction: 'rtl', }} sx={{fontSize: '20px'}}>{p.productCode} :מ. פריט</Typography>
                        <Typography align='right' sx={{fontSize: '20px'}}>{p.quantity} :כמות</Typography>
                        <Typography align='right' sx={{fontSize: '20px'}}>₪{Number(p.sellPrice * 18).toFixed(2)} :מ.סופי</Typography>
                        <Typography align='right' sx={{fontSize: '20px'}}>₪{p.sellPrice - p.getPrice} :רווח</Typography>
                      </Stack>
                    </Typography>
                    <CardActions>
                      <Stack direction='column' spacing={0.5}>
                        <Button onClick={() => navigate(`/Products/edit/${p._id}`)}
                            sx={{borderRadius: '30px', backgroundColor: '#2549FE', boxShadow: 2, color: 'white'}}><Edit/></Button>
                        <Button onClick={() => setCurrentId(p._id)}
                            sx={{borderRadius: '30px', backgroundColor: '#FF0000', boxShadow: 2, color: 'white'}}><Delete/></Button> 
                      </Stack>                    
                    </CardActions>
                  </Stack>
                </CardContent>
              </Card>
              {currentId === p._id && <Dialog title={`? האם אתה רוצה למחוק ${p.name}`} dialogState={setCurrentId} yesFunction={handleDeleteProduct} itemId={p._id}/>}
              </>
            )))}
            {scrollLoading && (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflowX: 'hidden', overflowY: 'hidden' }}>
              <CircularProgress
                disableShrink
                size={60}
                thickness={5}
                style={{ marginTop: '20px' }}
              />
            </Box>)}
        </Stack>
      </Box>
    </Box>
  )
}

export default Products