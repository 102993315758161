import { useNotification } from '@pankod/refine-core';
import { Box, Button, CircularProgress, FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput, TextField, TextareaAutosize, Typography } from '@pankod/refine-mui'
import { FieldValues, useForm } from "@pankod/refine-react-hook-form";
import React, { useContext, } from "react";
import { ColorModeContext } from "contexts";

const CreateProduct = () => {
  const { open } = useNotification();
  const { mode } = useContext(ColorModeContext);
  const textColor = mode === 'light' ? 'black' : 'white';

  const [sellPrice, setSellPrice] = React.useState(0);
  const [sellPriceWithVAT, setSellPriceWithVAT] = React.useState(0);

  const handleSellPriceChange = (e:any) => {
    const value = parseFloat(e.target.value);
    if (!isNaN(value)) {
      setSellPrice(value);
      setSellPriceWithVAT(value * 1.18);
    }
  };

  const handleSellPriceWithVATChange = (e:any) => {
      const value = parseFloat(e.target.value);
      if (!isNaN(value)) {
        setSellPriceWithVAT(value);
        setSellPrice(value / 1.18);
      }
  };


  const {
    refineCore: { onFinish, formLoading },
    register,
    handleSubmit,
  } = useForm<any>({
    refineCoreProps: {
      onMutationError: (data:any, variables:any, context:any) => {
          if (data?.response) {
            let responseMessage = data?.response?.data?.message
            open?.({
              type: "error",
              message: 'טעות בנתונים',
              undoableTimeout: 6,
              description: responseMessage,
            });
          }
        },
    },
  });

  const onFinishHandler = async (data: FieldValues) => {
    await onFinish({
      ...data,
      sellPrice: sellPrice
    });
  };

  return (
    <Box
    borderRadius="10px"
    padding="15px">
      <form onSubmit={handleSubmit(onFinishHandler)} style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
       }}>

        <FormHelperText sx={{textAlign: 'right'}}>
           <Typography sx={{fontWeight: "bold", fontSize: 21, color: textColor}}>שם המוצר</Typography>
        </FormHelperText>
        <FormControl fullWidth>
        <TextField
          fullWidth
          id="outlined-basic"
          color="info"
          variant="outlined"
          inputProps={{ style: { direction: 'rtl', color: textColor, fontSize: '18px' } }}
          {...register("name", { required: true })}
        />
        </FormControl><br/>

        <FormHelperText sx={{textAlign: 'right'}}>
           <Typography sx={{fontWeight: "bold", fontSize: 21, color: textColor}}>מספר פריט</Typography>
        </FormHelperText>
        <FormControl fullWidth>
        <TextField
          fullWidth
          id="outlined-basic"
          color="info"
          variant="outlined"
          inputProps={{ style: { direction: 'rtl', color: textColor, fontSize: '18px' } }}
          {...register("productCode", { required: true })}
        />
        </FormControl><br/>

        <FormHelperText sx={{textAlign: 'right'}}>
           <Typography sx={{fontWeight: "bold", fontSize: 21, color: textColor}}>הסבר על המוצר</Typography>
        </FormHelperText>
        <FormControl fullWidth>
        <TextareaAutosize
          style={{
            width: "100%",
            background: "transparent",
            fontSize: '18px',
            borderColor: textColor,
            borderRadius: 6,
            padding: 10,
            color: textColor,
            direction: 'rtl', 
            resize: 'none',
          }}
          minRows={2}
          color="primary"
          id="outlined-basic"
          {...register("description", )}
        />
        </FormControl><br/>

        <FormHelperText sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', color: textColor}}>
          המיקום במחסן
        </FormHelperText>
        <FormControl fullWidth>
        <TextField
          fullWidth
          id="outlined-basic"
          color="info"
          variant="outlined"
          inputProps={{ style: { direction: 'rtl', color: textColor, fontSize: '18px' } }}
          {...register("place", )}
        />
        </FormControl><br/>

        <FormControl fullWidth>
          <FormHelperText sx={{textAlign: 'right'}}>
            <Typography sx={{fontWeight: "bold", fontSize: 21, color: textColor,}}>כמות</Typography>
          </FormHelperText>
          <TextField
           type="number"
           fullWidth
           id="outlined-basic"
           color="info"
           variant="outlined"
           inputProps={{ style: { color: textColor, fontSize: '18px' } }}
          {...register("quantity", { required: true })}/>
        </FormControl><br/><br/>

        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-adornment-amount">
            <Typography sx={{fontWeight: "bold", fontSize: 22, color: textColor}}>מחיר קניה</Typography>
          </InputLabel>
          <OutlinedInput 
            type="number"
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">₪</InputAdornment>}
            inputProps={{
              step: 0.0000000000000000000000001,
              pattern: "^[0-9]*\\.?[0-9]+$",
              style: { color: textColor, fontSize: '18px' },
            }}
            label="Amount"
            {...register("getPrice", { required: true })}
          />
        </FormControl><br/><br/>

        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-adornment-amount">
            <Typography sx={{fontWeight: "bold", fontSize: 22, color: textColor}}>מחיר מכירה</Typography>
          </InputLabel>
          <OutlinedInput 
            type="number"
            id="outlined-adornment-amount"
            startAdornment={<InputAdornment position="start">₪</InputAdornment>}
            inputProps={{
              step: 0.0000000000000000000000001,
              pattern: "^[0-9]*\\.?[0-9]+$",
              style: { color: textColor, fontSize: '18px' },
            }}
            value={sellPrice}
            onChange={handleSellPriceChange}
            label="Amount"
            //{...register("sellPrice", { required: true })}
          />
        </FormControl><br/><br/>

        <FormControl fullWidth>
          <InputLabel htmlFor="outlined-adornment-amount-vat">
            <Typography sx={{fontWeight: "bold", fontSize: 22, color: textColor}}>מחיר מכירה כולל מע"ם</Typography>
          </InputLabel>
          <OutlinedInput 
            type="number"
            id="outlined-adornment-amount-vat"
            startAdornment={<InputAdornment position="start">₪</InputAdornment>}
            inputProps={{
              step: 0.01,
              style: { color: textColor, fontSize: '18px' },
            }}
            value={sellPriceWithVAT}
            onChange={handleSellPriceWithVATChange}
            label="Amount"
          />
        </FormControl><br/><br/>


        <Button type="submit" variant="contained" color="success" disabled={formLoading}>הוספה</Button>
            {formLoading && <CircularProgress/>}
      </form>
    </Box>
  )
}

export default CreateProduct
