import { useLogout, useShow, useDelete, useNotification } from '@pankod/refine-core'
import { useNavigate } from '@pankod/refine-react-router-v6'
import { Box, Button, Card, CardContent, CircularProgress, IconButton, LinearProgress, Stack, Table, TableBody, TableCell,
   TableContainer, TableHead, TableRow, Tooltip, Typography } from '@pankod/refine-mui';
import { Edit, Delete, } from '@mui/icons-material';
import axios from 'axios';
import { useState } from 'react';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { url } from 'assets/url';
import EditProductModal from 'components/EditProductModal';
import Dialog from '../components/dialog'

const SupplyDetails = () => {
  const { open } = useNotification();
  const { queryResult } = useShow();
  const navigate = useNavigate();
  const { data, isLoading, isError, refetch } = queryResult;
  const { mutate: mutateLogout } = useLogout();
  const { mutate } = useDelete();
  const [showLoading, setShowLoading] = useState(false);
  const [showCompare, setShowCompare] = useState(false);
  const [conformationDialog, setConformationDialog] = useState(false);
  const [cheaper, setCheaper] = useState([]);
  const supply = data?.data;
  const products = supply?.supplyProducts;

  const [isUpdateProductDialogOpen, setIsUpdateProductDialogOpen] = useState(false);
  const [isDeleteProductDialogOpen, setIsDeleteProductDialogOpen] = useState(false);

  if(isLoading) return <center><CircularProgress disableShrink /></center>
  if(isError) { 
    mutateLogout() 
    navigate('/login') 
  }

  const handlePricesCompareClick = () => {
    let id = supply?._id
    setShowLoading(true);
    axios.post(`${url}/Supplies/compare`, {id}, {
    withCredentials: true,
    }).then((res) => {
      setShowLoading(false);
      setCheaper(res.data);
      setShowCompare(true)
      console.log(res.data);
    }).catch((error) => {
      console.error(error);
    });
  };

  const removeOrder = () => {
    setConformationDialog(true);
  }

  const handleClickSupplyRemoved = (id: String) => {
    mutate(
      {
        resource: "Supplies",
        id: id as string,
      },
      {
        onSuccess: () => {
          navigate("/Supplies");
        },
      },
    );
  };

  const getTotalPrice = (products : any) => {
    return products.reduce((totalPrice : any, product : any) => {
      const price = product.product.lastPrice;
      const chosenQuantity = product.product.quantity;
      return totalPrice + price * chosenQuantity;
    }, 0);
  };

  const handleUpdateProductYesFunction = async (updatedProduct:any) => {
    try {
      let response = await axios.post(`${url}/Supplies/updateProductInSupply/${supply?._id}`, updatedProduct, { withCredentials: true });
      if (response.status === 200) {
        open?.({
          type: "success", 
          message: 'עודכן בהצלחה',
          undoableTimeout: 3,
        });
        navigate('/Supplies')
      }
      setIsUpdateProductDialogOpen(false);
    } catch (error:any) {
      const responseMessage = error.response?.data?.message || 'טעות בנתונים';
      console.error("There was an error submitting the form!", error);
      open?.({
        type: "error",
        message: 'Error',
        undoableTimeout: 6,
        description: responseMessage,
      });
    }
  };

  const handleDeleteProductYesFunction = async (product:any) => {
    try {
      let response = await axios.post(`${url}/Supplies/deleteProductInSupply/${supply?._id}`, {removedProduct: product}, { withCredentials: true });
      if (response.status === 200) {
        open?.({
          type: "success", 
          message: 'נמחק בהצלחה',
          undoableTimeout: 3,
        });
        refetch()
      }
      setIsDeleteProductDialogOpen(false);
    } catch (error:any) {
      const responseMessage = error.response?.data?.message || 'טעות בנתונים';
      console.error("There was an error submitting the form!", error);
      open?.({
        type: "error",
        message: 'Error',
        undoableTimeout: 6,
        description: responseMessage,
      });
    }
  };
  
  return (
    <Box>
      <Stack direction='row-reverse' justifyContent='space-between'>
        <Typography sx={{fontSize: '22px', fontWeight: 'bold', direction: 'rtl', }}>הזמנה מס": {supply?.invoicingNum}</Typography>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 3 }}>
        <Button variant="contained" sx={{boxShadow: 3, borderRadius: 5, bgcolor: '#FF3737', color: '#FFFFFF'}} startIcon={<Delete/>} onClick={() => removeOrder()}>מחק</Button>
          <Button variant="contained" sx={{boxShadow: 3, borderRadius: 5}} startIcon={<Edit/>}
            onClick={() => navigate(`/Supplies/edit/${supply?._id}`)}>עריכה</Button>
          <Button onClick={handlePricesCompareClick} sx={{ display: { xs: 'none', sm: 'block'},
                boxShadow: 3, borderRadius: 10, bgcolor: '#43D0FF', color: '#FFFFFF'}} variant="contained">השוואת מחירים</Button>
        </Stack>
      </Stack><br/>
      <Stack direction={{xs: 'column', sm: 'column', md: 'column', lg: 'row-reverse'}}
        justifyContent="space-between"
        alignItems="flex-end">
         <Typography sx={{fontSize: '22px', fontWeight: 'bold', direction: 'rtl', }}>ספק: {supply?.supplier.companyName}</Typography>
         {(!supply?.ifPayed && <Typography align='center' sx={{fontSize: '22px', fontWeight: 'bold', bgcolor:'#FA8A79', borderRadius:'7px'}}>שולם: ₪{Number(getTotalPrice(products) * (1 - (supply?.dedication/100)) * 18).toFixed(2)}\₪{Number(supply?.Payed).toFixed(2)}</Typography>)
         || (supply?.ifPayed && <Typography align='center' sx={{fontSize: '22px', fontWeight: 'bold', bgcolor:'#99FA60', borderRadius:'7px'}}>שולם: ₪{Number(getTotalPrice(products) * (1 - (supply?.dedication/100)) * 1.18).toFixed(2)}\₪{Number(supply?.Payed).toFixed(2)}</Typography>)}
         {(supply?.credit > 0 && <Typography align='center' sx={{fontSize: '22px', fontWeight: 'bold', bgcolor:'#FBAC7A', borderRadius:'7px'}}>זיכוי: {supply?.credit}</Typography>)
         || <Typography align='center' sx={{fontSize: '22px', fontWeight: 'bold', bgcolor:'#99FA60', borderRadius:'7px'}}>זיכוי: {supply?.credit}</Typography>}
         <Typography sx={{fontSize: '22px', fontWeight: 'bold'}}>{new Date(supply?.Time).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' })}</Typography>
      </Stack>
      <Typography sx={{fontSize: '22px', fontWeight: 'bold', textAlign: 'right', direction: 'rtl',}}>תיאור: {supply?.description}</Typography><br/>
      <Box sx={{display: { xs: 'none', md: 'block' }}}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{bgcolor: '#232323'}}>             
              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>פעולות</TableCell>
              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>כולל מע"ם</TableCell>
              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>סה"כ</TableCell>
              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>מחיר נטו</TableCell>
              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>%</TableCell>
              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>מחיר ליחיד</TableCell>
              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>כמות</TableCell>
              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>מספר פריט</TableCell>
              <TableCell sx={{fontSize: '21px', fontWeight: 'bold', color: '#ffffff', textAlign: 'right'}}>שם מוצר</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((p : any) => (
              <TableRow key={p._id}>                     
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>
                  <Tooltip title={<Typography style={{ fontSize: '17px' }}>עריכת מוצר</Typography>} placement="top">
                    <IconButton color="primary" onClick={() => setIsUpdateProductDialogOpen(p._id) }>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={<Typography style={{ fontSize: '17px' }}>מחיקת מוצר</Typography>} placement="top">
                    <IconButton style={{ color: '#FF0000' }} onClick={() => setIsDeleteProductDialogOpen(p._id) }>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell> 
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{Number(p.product.lastPrice * p.product.quantity * 1.18).toFixed(2)} ₪</TableCell> 
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{Number(p.product.lastPrice * p.product.quantity).toFixed(2)} ₪</TableCell> 
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{Number(p.product.lastPrice).toFixed(2)} ₪</TableCell>
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{p.product.deduction}</TableCell>
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{Number(p.product.firstPrice).toFixed(2)}₪</TableCell>
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right'}}>{p.product.quantity}</TableCell>
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', wordBreak: 'break-word', }} style={{ direction: 'rtl', }}>{p.product.productCode}</TableCell>
                <TableCell sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', wordBreak: 'break-word', }} style={{ direction: 'rtl', }}>{p.product.productName}</TableCell>
                {isUpdateProductDialogOpen === p._id &&  <EditProductModal
                  dialogState={setIsUpdateProductDialogOpen}
                  yesFunction={handleUpdateProductYesFunction}
                  product={p}
                />} {isDeleteProductDialogOpen === p._id &&  <Dialog
                  title={`? האם אתה רוצה למחוק המוצר ${p.product.productName}`}
                  dialogState={setIsDeleteProductDialogOpen}
                  yesFunction={handleDeleteProductYesFunction}
                  itemId={p}
                />}
              </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Box>
      <Box sx={{display: { xs: 'block', md: 'none' }}}>
      <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={1.5}>
            {products.map((p : any) => (
              <Card sx={{ boxShadow: 5, borderRadius: 3, border: 1}} key={p._id}>
                <CardContent>
                  <Stack direction='column' justifyContent="space-between" spacing={0.3}> 
                    <Typography fontSize={22} fontWeight='bold' style={{ direction: 'rtl', }} sx={{textAlign: 'right'}}>
                      {p.product.productName}
                    </Typography>
                    <Typography sx={{fontSize: '19px', textAlign: 'right'}} style={{ direction: 'rtl', }}>מק"ט: {p.product.productCode}</Typography>
                    <Stack direction='row-reverse' justifyContent="space-between">
                      <Typography sx={{fontSize: '19px', textAlign: 'right'}}>מחיר ליחידה: ₪{p.product.firstPrice}₪</Typography>
                      <Typography sx={{fontSize: '19px', textAlign: 'right'}}>הנחה: {Number(p.product.deduction).toFixed(2)}%</Typography>
                    </Stack>
                    <Typography sx={{fontSize: '19px', textAlign: 'right'}}>מחיר נטו: {Number(p.product.lastPrice).toFixed(2)}₪</Typography>
                    <Typography sx={{fontSize: '19px', textAlign: 'right' }}>כמות: {p.product.quantity}</Typography>
                    <Typography sx={{fontSize: '19px', fontWeight: 'bold', textAlign: 'left'}}>סכ"ה: ₪{Number(p.product.lastPrice * p.product.quantity).toFixed(2)}₪</Typography>
                  </Stack>
                </CardContent>
            </Card>
            ))}
        </Stack>
      </Box>
      <TableRow>
          <TableCell sx={{ fontSize: '19px', fontWeight: 'bold', }} align="right">{Number(getTotalPrice(products)).toFixed(2)}</TableCell>
          <TableCell sx={{ fontSize: '19px', fontWeight: 'bold', }}>:סכום</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ fontSize: '19px', fontWeight: 'bold', }} align="right">{supply?.dedication}%</TableCell>
          <TableCell sx={{ fontSize: '19px', fontWeight: 'bold', }}>:הנחה</TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ fontSize: '19px', fontWeight: 'bold', }} align="right">{Number(getTotalPrice(products) * (1 - (supply?.dedication/100))).toFixed(2)}</TableCell>
          <TableCell sx={{ fontSize: '19px', fontWeight: 'bold', }}>:סה"כ לפני מעם</TableCell>
      </TableRow>
      <TableRow>
          <TableCell sx={{fontSize: '19px', fontWeight: 'bold', }} align="right">{Number((getTotalPrice(products) * (1 - (supply?.dedication/100))) * 0.18).toFixed(2)}</TableCell>
          <TableCell sx={{fontSize: '19px', fontWeight: 'bold', }}>:מע"ם 18%</TableCell>
      </TableRow>
      <TableRow>
          <TableCell sx={{fontSize: '19px', fontWeight: 'bold', }} align="right">{Number(getTotalPrice(products) * (1 - (supply?.dedication/100))
                                  + ((getTotalPrice(products) * (1 - (supply?.dedication/100))) * 0.18)).toFixed(2)}</TableCell>
          <TableCell sx={{fontSize: '19px', fontWeight: 'bold', }}>:לתשלום</TableCell>
      </TableRow>
      <br/>
      {showLoading && <LinearProgress/>}
      {showCompare && ((cheaper.length === 0 && 
      <Typography sx={{fontSize: '28px', fontWeight: 'bold'}}>אין הזמנות לאותו מוצרים במחירים נמוכים</Typography>) 
      || (cheaper.length > 0 && cheaper.map((item : any) => (
        <Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{fontWeight: 'bold', textAlign: 'right', bgcolor: '#C7C7C7'}}>{item.supplier}</TableCell>
                  <TableCell sx={{fontWeight: 'bold', textAlign: 'right', bgcolor: '#C7C7C7'}}>ספק</TableCell>
                  <TableCell sx={{fontWeight: 'bold', textAlign: 'right', bgcolor: '#C7C7C7'}}>{new Date(item.Time).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' })}</TableCell>
                  <TableCell sx={{fontWeight: 'bold', textAlign: 'right', bgcolor: '#C7C7C7'}}>זמן ההזמנה</TableCell>
                  <TableCell sx={{fontWeight: 'bold', textAlign: 'right', bgcolor: '#C7C7C7'}}>{item.invoicingNum}</TableCell>
                  <TableCell sx={{fontWeight: 'bold', textAlign: 'right', bgcolor: '#C7C7C7'}}>מספר הזמנה</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                  <TableRow>
                    <TableCell sx={{fontWeight: 'bold', textAlign: 'right', bgcolor: '#D5D5D5E8'}}>סב"כ בש"ח</TableCell>
                    <TableCell sx={{fontWeight: 'bold', textAlign: 'right', bgcolor: '#D5D5D5E8'}}>מחיר נטו</TableCell>
                    <TableCell sx={{fontWeight: 'bold', textAlign: 'right', bgcolor: '#D5D5D5E8'}}>%</TableCell>
                    <TableCell sx={{fontWeight: 'bold', textAlign: 'right', bgcolor: '#D5D5D5E8'}}>מחיר ליחיד</TableCell>
                    <TableCell sx={{fontWeight: 'bold', textAlign: 'right', bgcolor: '#D5D5D5E8'}}>כמות</TableCell>
                    <TableCell sx={{fontWeight: 'bold', textAlign: 'right', bgcolor: '#D5D5D5E8'}}>מספר פריט</TableCell>
                  </TableRow>
                  {item.products.map((product : any) => (
                    <TableRow key={product.productCode}>                     
                      <TableCell sx={{fontWeight: 'bold', textAlign: 'right'}}>{product.product.lastPrice * product.product.quantity} ₪</TableCell> 
                      <TableCell sx={{fontWeight: 'bold', textAlign: 'right'}}>{product.product.lastPrice} ₪</TableCell>
                      <TableCell sx={{fontWeight: 'bold', textAlign: 'right'}}>{product.product.deduction}</TableCell>
                      <TableCell sx={{fontWeight: 'bold', textAlign: 'right'}}>{product.product.firstPrice}₪</TableCell>
                      <TableCell sx={{fontWeight: 'bold', textAlign: 'right'}}>{product.product.quantity}</TableCell>
                      <TableCell sx={{fontWeight: 'bold', textAlign: 'right'}}>{product.product.productCode}</TableCell>
                    </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br/><br/>
        </Box>
      ))))}
      {conformationDialog && <ConfirmationDialog dialogState={setConformationDialog} yesFunction={() => handleClickSupplyRemoved(supply?._id)} message={`האם אתה מאשר למחוק הזמנת הספק שמספרה: ${supply?.invoicingNum} ?`}/>}
    </Box>
  )
}
export default SupplyDetails