import { useForm } from "@pankod/refine-react-hook-form";
import { Button, FormControl, FormHelperText, Stack, TextField, TextareaAutosize, Typography, } from '@pankod/refine-mui';
import { useContext } from 'react'
import { ColorModeContext } from "contexts";
const EditSupply = () => {
  const { mode } = useContext(ColorModeContext);
  const textColor = mode === "light" ? "black" : "white";

  const {
      refineCore: { onFinish, formLoading, queryResult, },
      register,
      handleSubmit,
  } = useForm();

  const getTotalPrice = (products : any) => {
    return products.reduce((totalPrice : any, product : any) => {
      const price = product.product.lastPrice;
      const chosenQuantity = product.product.quantity;
      return totalPrice + price * chosenQuantity;
    }, 0);
  };

  const supply = queryResult?.data?.data
  const totalPrice = Number(getTotalPrice(supply?.supplyProducts)) * (1-(supply?.dedication/100)) * 1.18;

  return (
    <div>
      <form onSubmit={handleSubmit(onFinish)} style={{ 
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
       }}>
        <FormHelperText sx={{fontSize: '21px', fontWeight: 'bold', textAlign: 'right', color: textColor}}>
            תיאור
        </FormHelperText>
        <FormControl fullWidth>
        <TextareaAutosize
          style={{
            width: "100%",
            background: "transparent",
            fontSize: "18px",
            borderColor: "rgba(0,0,0,0.23)",
            borderRadius: 6,
            padding: 10,
            color: textColor,
            direction: 'rtl', 
            resize: 'none',
            textAlign: 'right',
          }}
          minRows={4}
          color="primary"
          id="outlined-basic"
          {...register("description")}
        />
        </FormControl><br/>

        <FormHelperText sx={{textAlign: 'right'}}>
           <Typography sx={{fontWeight: "bold", fontSize: 21, color: textColor}}>מספר חשבונית</Typography>
        </FormHelperText>
        <FormControl fullWidth>
        <TextField
           fullWidth
           required
           id="outlined-basic"
           variant="outlined"
           inputProps={{ style: { color: textColor, fontSize: '17px' } }}
           sx={{ direction: 'rtl', color: textColor }}
          {...register("invoicingNum", { required: true })}
        />
        </FormControl><br/>
        
        <FormHelperText sx={{textAlign: 'right'}}>
           <Typography sx={{fontWeight: "bold", fontSize: 21, color: textColor}}>מצב תשלום</Typography>
        </FormHelperText>
        <FormControl fullWidth>
          <Stack direction="row"
            justifyContent="center"
            alignItems="flex-start"
            spacing={2}
          >
            <Typography sx={{fontSize: { xs: '20px', sm: '22px', md: '25px' }, color: textColor,
              fontWeight: 'bold', mt: 1, direction: 'rtl', whiteSpace: 'nowrap',}}>מ- {Number(totalPrice).toFixed(2)}₪</Typography>
            <TextField
              fullWidth
              required
              type="number"
              inputProps={{ 
                min: 0,
                step: 0.0000000000000000000000001,
                pattern: "^(?:\\d+)?(?:\\.\\d+)?$",
                style: { color: textColor, fontSize: '17px' }
              }}
              id="outlined-basic"
              color="info"
              variant="outlined"
              {...register("Payed", { required: true })}
            />
          </Stack>
        
        </FormControl><br/>

        <FormHelperText sx={{textAlign: 'right'}}>
           <Typography sx={{fontWeight: "bold", fontSize: 21, color: textColor}}>זיכוי</Typography>
        </FormHelperText>
        <FormControl fullWidth>
        <TextField
          fullWidth
          required
          type="number"
          inputProps={{ 
            min: 0,
            step: 0.0000000000000000000000001,
            pattern: "^(?:\\d+)?(?:\\.\\d+)?$",
            style: { color: textColor, fontSize: '17px' }
          }}
          id="outlined-basic"
          variant="outlined"
          sx={{ direction: 'rtl', }}
          {...register("credit", { required: true })}
        />
        </FormControl><br/>

        <Button type="submit" variant="contained" color="success" disabled={formLoading}>עריכה</Button>
        
            {formLoading && <p>...טוען</p>}
      </form>
    </div>
  )
}

export default EditSupply